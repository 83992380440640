<template>
 <el-dropdown :hide-on-click="false">
    <i v-bind="[item]" class="iconfont" :class="item.icon"></i>
    <el-dropdown-menu slot="dropdown" :hide-on-click="false">
      <el-dropdown-item v-bind="[menuItem]" v-for="menuItem in item.items" :key="menuItem.prop">
        <el-checkbox :checked="menuItem.checked" v-model="menuItem.show"
          @change="checked=>checkedChange(checked, menuItem.changeEvent, menuItem)">
          {{menuItem.label}}
        </el-checkbox>
      </el-dropdown-item>
    </el-dropdown-menu>
</el-dropdown>

</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },

    columns: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    // 单选框变更事件
    checkedChange(val, fun, menuItem) {
      if (fun && typeof (fun) === 'function') {
        fun(val, this.columns, menuItem)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
